.about-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }

  .about-container h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }

  .about-container p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
  }

  .about-link {
    color: #1a73e8;
    text-decoration: none;
    font-weight: bold;
    font-size: 1.1rem;
  }

  .about-link:hover {
    text-decoration: underline;
  }

  .linkedin-link {
    color: #0077b5;
    font-weight: bold;
    text-decoration: none;
  }

  .linkedin-link:hover {
    color: #005582;
    text-decoration: underline;
  }

  .linkedin-icon {
    margin-left: 8px;
    color: #0077b5;
    font-size: 1.4rem;
  }

  .linkedin-icon:hover {
    color: #005582;
  }
