.molsvg-container{
    height: 400px;
    width: 400px;
}

.molecule-banner-container{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
}

.mol-detail-btn-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 70%;
}

.downloadbtn:hover{
    color:#ffffff;
}

.molecule-card{
    height: 200px;
    width: 600px;
    overflow: hidden;
}

.smiles-expand{
    word-break: break-word;
    white-space: normal;
    max-width: 100%;
    overflow-wrap: break-word;
}
