.App {

}
.App .ant-layout-content{
    padding: 24px;
    min-height: 80vh;
    background-color: white;
}

/* .App .ant-layout-content div{
  margin: 24px;
} */

.dashboard-sider .ant-menu{
  height: 100%;
}

.ant-typography-expand{
  color: #27ddcc!important;
}

.dashboard-sider .ant-menu .ant-menu-item-selected{
  background-color: #27ddcc!important;
  color: white;
}

.dashboard-sider .ant-menu .ant-menu-submenu-selected .ant-menu-submenu-title{
  background-color: #27ddcc!important;
  color: white;
}

/* CSS for loader */

/* Absolute Center Spinner */
.loading {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  /* Transparent Overlay */
  .loading:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
      background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  }

  /* :not(:required) hides these rules from IE9 and below */
  .loading:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }

  .loading:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 150ms infinite linear;
    -moz-animation: spinner 150ms infinite linear;
    -ms-animation: spinner 150ms infinite linear;
    -o-animation: spinner 150ms infinite linear;
    animation: spinner 150ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
  }



  #loading-wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  #loading-text {
    display: block;
    position: absolute;
    top: 51%;
    left: 50%;
    color: #27ddcc;
    width: 100px;
    height: 30px;
    margin: -7px 0 0 -45px;
    text-align: center;
    font-family: 'PT Sans Narrow', sans-serif;
    font-size: 20px;
  }

  #loading-content {
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 170px;
    height: 170px;
    margin: -85px 0 0 -85px;
    border: 3px solid #F00;
  }

  #loading-content:after {
    content: "";
    position: absolute;
    border: 3px solid #0F0;
    left: 15px;
    right: 15px;
    top: 15px;
    bottom: 15px;
  }

  #loading-content:before {
    content: "";
    position: absolute;
    border: 3px solid #00F;
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
  }

  #loading-content {
    border: 3px solid transparent;
    border-top-color: #02ae9e;
    border-bottom-color: #02ae9e;
    border-radius: 50%;
    -webkit-animation: loader 2s linear infinite;
    -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 2s linear infinite;
  }

  #loading-content:before {
    border: 3px solid transparent;
    border-top-color: #27ddcc;
    border-bottom-color: #27ddcc;
    border-radius: 50%;
    -webkit-animation: loader 3s linear infinite;
      -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
    animation: loader 3s linear infinite;
  }

  #loading-content:after {
    border: 3px solid transparent;
    border-top-color: #d0f2ee;
    border-bottom-color: #d0f2ee;
    border-radius: 50%;
    -webkit-animation: loader 1.5s linear infinite;
    animation: loader 1.5s linear infinite;
      -moz-animation: loader 2s linear infinite;
    -o-animation: loader 2s linear infinite;
  }

  @-webkit-keyframes loaders {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes loader {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  #content-wrapper {
    color: #FFF;
    position: fixed;
    left: 0;
    top: 20px;
    width: 100%;
    height: 100%;
  }

  #header
  {
    width: 800px;
    margin: 0 auto;
    text-align: center;
    height: 100px;
    background-color: #666;
  }

  #content
  {
    width: 800px;
    height: 1000px;
    margin: 0 auto;
    text-align: center;
    background-color: #888;
  }
