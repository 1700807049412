.main-navbar{
    background-color: #27ddcc;
    height:80px;
  }

.main-navbar.mb-3{
margin-bottom: 0!important;
}

.navbar-logo{
display: block;
top: 0;
left: 0;
width: 100px;
height: auto;
object-fit: contain;
}

.brand-name{
    color:#303030;
    font-weight: 600;
}

.cart-menu{
  background: #27ddcc;
  display: flex;
  justify-content: center;
}

.cart-menu:hover{
  transform: scale(1.1)
}

.logout-link{
  padding-right: 30px!important;
}

.logout-link:hover{
  padding-right: 30px!important;
  transform: scale(1.1)
}
