.quantity-control{
    display: flex;
    justify-content: space-evenly;

}

.quantity-control>p{
    margin-bottom: 0px;
}

.billingcart-card{
    margin-top: 37px!important;
    width: 1174px!important;
}

.cart-input{
    width: 90%;
}

.cart-head-container{
    display: flex;
    justify-content: space-between;
}
.cart-head-container .past-orders{
    margin: 10px;
}
