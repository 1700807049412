.container-up{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 40px;
}

.ant-col{
    margin-right: 15px;
}

.calculation-details{
    width: 30%;
}

.calculation-selection{
    width: 90%;
    margin: auto;
}

.calculation-list{
    width: 60%;
}

.calculation-list li{
    margin-bottom: 10px;
}

.calculation-list .cal-result-header{
    text-align: center;
    margin-bottom: 10px;
}

.calculation-selection .cal-result-header{
    text-align: center;
    margin-bottom: 10px;
}

.calculation-list .cal-result-status{
    margin-top: 2%;
}

.calculation-list .cal-result-status span{
    margin-left: 20%;
    margin-top: 20%;
}

.calculation-details .ant-card-body div{
    margin-bottom: 5%;
}

.calculation-list .id-and-smiles{
    margin-left: 5%;
    margin-top:5%;
}
