@import url('https://fonts.googleapis.com/css2?family=Tomorrow&display=swap');

body {
  margin: 0;
  font-family: Tomorrow!important;
  /* font-family: Tomorrow, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #303030
}

code {
  font-family: Tomorrow;
  /* font-family: Tomorrow, source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type="file"] {
  width: 100%;
}
