.ant-table-extensions > div {
    justify-content: end!important;
}

.ant-table-extensions .ant-input-affix-wrapper{
    width: 20%!important;
    margin-left: 2%!important;
}

.result-table-btn-container {
    margin-bottom: -30px;
}

.selected-text {
    margin-left: 8px;
}
.downloadbtn:hover{
    color:#ffffff;
}
