.Home{
  background-image: url("../assets/backg.jpeg");
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  margin:-24px;
  height: 100%!important;
  min-height: 80vh;
}

.Home .lander {
    padding: 80px 0;
    text-align: center;
  }

.Home .lander h1 {
  font-weight: 600;
}

.Home .text-sub{
  font-size:35px;
}
.homepag-svg-container{
  text-align: center;
}

.homepag-svg-container .svg-txt{
  margin-top:10px;
  font-size:large;
  font-weight: 600;
}

.Home .homepage-svg{
  height: 100px;
}
