@media all and (min-width: 480px) {
    .Login {
      padding-top: 25px;
      padding-bottom: 40px;
    }

    .Login form {
      margin: 0 auto;
      max-width: 100%;
    }

    .Login .login-button{
      width: 100%;
      background-color: #27ddcc!important;
      border-color: #27ddcc!important;
    }
    .Login .login-button:hover{
      box-shadow: 0 8px 9px -4px #27998d, 0 4px 18px 0 #02ae9e;
      background-color: #02ae9e!important;
      border-color: #02ae9e!important;
    }
    .Login .login-button:focus-visible{
      box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
      background-color: #02ae9e!important;
      border-color: #02ae9e!important;
    }
    .Login .login-button:active{
      box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
      background-color: #02ae9e!important;
      border-color: #02ae9e!important;
    }

    .Login .links-container{
      display: flex;
      justify-content: space-between;
      margin-top: 12px;
      margin-bottom: 2px;
      font-size: 14px;
    }
    .Login .links-container > div{
      display: flex;
      align-items: center;
    }
    .Login .links{
      display: flex;
      flex-direction: column;
      color: #27ddcc;
      text-decoration: none;

    }
    .Login .sub-link{
      color: #27ddcc;
      text-decoration: none;
    }
  }

.login-container .left{
    width:80%;
    margin:auto;
    height: 100%;
    border-radius: 25px;
    border: 2px solid #f5f5f5;
}

.login-container .left-head{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 2%;
    padding:5%;
}

.login-container .left-bottom{
    padding: 15%;
}

.outer{
  width:40%;
  margin:auto;
}
.outer .label-bar{
  font-size: xx-large;
    font-weight: bold;
}

.logo-bar .logo-image{
  display: block;
  width: 50%;
  height: auto;
  object-fit: contain;
}

.label-bar{
  padding: 0 5% 0 5%;
}

.icon-bar{
  display:flex;
  justify-content: space-between;
}

.icon-bar .social-media-icon{
  height: 55px;
  width: 185px;
  justify-content: center;
  margin: 3%;
  padding: 2% 6% 2% 6%;
  display: flex;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
}

.icon-bar .social-media-icon:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 #d6d6d6;
  cursor: pointer;
}

.icon-bar .social-media-icon{
  filter: opacity(0.7);
}
.icon-bar .social-media-icon:hover {
  filter:grayscale(0);
}

.middle-container{
  display:flex
}

.middle-container .line{
  border-top: 3px solid #c6c5c5;
  width: 100%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 8%;
  margin-right: 8%;
}

.middle-container .text-line{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #6c6c6c;
}

.form-control{
  height: 48px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  margin-top: 4px;
  margin-bottom: 8px;
}
.form-control:focus-visible{
  outline: 1px solid #d6d6d6;
}
.form-control:focus{
  border-color:#bfbaba!important;
  box-shadow: 0 0 0 0.25rem #f4f3f3!important;
}

.form-label{
  color: var(--bs-body-color);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #f5fffe inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-check-input:active{
  background-color: #27ddcc!important;
  border-color: #27ddcc!important;
}

.form-check-input:checked[type=checkbox]{
  background-color: #27ddcc!important;
}

.form-check-input:checked[type=checkbox]:focus{
  box-shadow: none!important;
}

.form-check-input:focus{
  border-color: #27ddcc!important;
  box-shadow: none!important;
}

.sub-link:hover{
  text-decoration: underline;
}

.twitter-icon{
  height: 23px;
  margin-top:4px;
}
