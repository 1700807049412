.query-specs-row{
    display: flex;
    justify-content: space-between;
}

.query-specifications-card .refine-btn{
    margin-top: 3%;
}

.query-specifications-card{
    text-align: center;
}

.calc-name-wwrap{
    word-wrap: break-word;
}
