.K-Chem-Composer div{
    border: none!important;
  }

.K-Button{
    /* width: 100%; */
    background-color: #27ddcc!important;
    border-color: #17897d!important;
  }
  .K-Button:hover{
    box-shadow: 0 8px 9px -4px #27998d, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .K-Button:focus-visible{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .K-Button:active{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .K-Button.K-State-Active{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #17897d!important;
  }

  .K-Chem-Composer{
    border-radius: 5px;
    border: 1px solid #d6d6d6!important;
  }

  .K-Chem-Composer .K-Chem-Editor{
    border-radius: 5px;
    border: 1px solid #d6d6d6!important;
    outline: none!important;
    border-right:none!important;
  }

  .molecule-search{
    width: 20%;
    margin-top: 3%;
    background-color: #27ddcc!important;
    border-color: #27ddcc!important;
    /* margin-left: 550px; */
  }
  .molecule-search:hover{
    box-shadow: 0 8px 9px -4px #27998d, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .molecule-search:focus-visible{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .molecule-search:active{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }

  .top-container{
    display: flex;
    justify-content: space-around;
  }

  .draw-molecule{
    height: 500px;
    width: 64%;
  }
  .draw-tool{
    height: 400px;
    width: 100%;
    border: 1px solid black;
  }
  .generate-smiles{
    height: 500px;
    width: 30%;
  }

  .generate-smiles .smiles-text{
    height: 100px;
    width: 100%;
    margin-top: 15px;
    border: 1px solid #d6d6d6!important;
    border-radius: 5px;
  }

  .mol-search-btn-container{
    display: flex;
    width: 100%;
    justify-content: center;
  }
