@media all and (min-width: 480px) {
    .Signup {
      padding-top: 25px;
      padding-bottom: 40px;
    }

    .Signup .signup-button{
      width:100%;
      background-color: #27ddcc!important;
      border-color: #27ddcc!important;
    }

    .Signup .signup-button:hover{
      box-shadow: 0 8px 9px -4px #27998d, 0 4px 18px 0 #02ae9e;
      background-color: #02ae9e;
      border-color: #02ae9e;
    }

    .Signup .signupbutton:focus-visible{
      box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
      background-color: #02ae9e;
      border-color: #02ae9e;
    }

    .Signup .signup-button:active{
      box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
      background-color: #02ae9e;
      border-color: #02ae9e;
    }
  }

  .outer{
    display:block;
    width:40%;
    margin:auto;
  }

  .outer .label-bar{
    font-size: xx-large;
      font-weight: bold;
  }

  .form-label{
    color: var(--bs-body-color);
  }

  .form-control{
    height: 48px;
    border-radius: 5px;
    border: 1px solid #d6d6d6;
    margin-top: 4px;
    margin-bottom: 8px;
  }

  .outer .checkbox{
    padding: 10px;
  }

  .outer .Signup .email-bar{
    display:flex;
    justify-content:space-between;
  }

  .Signup .sub-link{
    color: #27ddcc;
    text-decoration: none;
  }

  .Signup .sub-link:hover{
    text-decoration: underline;
  }

  .form-label {
    margin-bottom: 0.5rem;
    margin-top: 17px;
}
