.container-up{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 40px;
}
.ant-col{
    margin-right: 15px;
}

.account-card{
    width: 400px;
}

.personal-card{
    width: 600px;
}

.shipping-card{
    width: 1040px;
    margin: auto;
}
