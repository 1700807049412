.footer-base{
    color: #f5f5f5;
    background-color: #6c6c6c;
}

footer{
    background-color: #f5f5f5;
}

.brand-container{
    display:flex;
}
.brand-container .navbar-logo{
    display: block;
    top: 0;
    left: 0;
    width: 30px;
    height: auto;
    object-fit: contain;
    }

.brand-container .brand-name{
    display: flex;
    align-items:center;
}
.footer-outer{
    display: flex;
}
