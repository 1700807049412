.query-submit{
    margin:10px;
    height: 6vh;
    width: 16vw;
}

.ant-btn{
    background-color: #27ddcc!important;
    border-color: #27ddcc!important;
    color: white!important;
  }

.ant-btn:hover{
box-shadow: 0 8px 9px -4px #27998d, 0 4px 18px 0 #02ae9e;
background-color: #02ae9e!important;
border-color: #02ae9e!important;
}

.ant-btn:focus-visible{
box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
background-color: #02ae9e!important;
border-color: #02ae9e!important;
}

.ant-btn:active{
box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
background-color: #02ae9e!important;
border-color: #02ae9e!important;
}

.ruleGroup {
    border-color: #27ddcc8a;
    background: #f5fffe;
}
.ruleGroup-body .ruleGroup {
    border-color: #27ddcc8a;
    background: #d0f2ee;
}

input[type="number"].rule-value-list-item{
    border: none!important;
    line-height: 190%;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
}

input[type="number"].rule-value{
    border: none!important;
    line-height: 190%;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
}

input[type="number"].rule-value-list-item:focus-visible {
    /* border: none!important; */
    outline: 0;
    line-height: 190%;
    border-radius: 7px;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    border: 1px solid #1677ff!important;
}

input[type="number"].rule-value:focus-visible {
    /* border: none!important; */
    outline: 0;
    line-height: 190%;
    border-radius: 7px;
    box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
    border: 1px solid #1677ff!important;
}

.dynamic-unit-div{
    background-color: white;
    width: 15%;
    text-align: center;
    border: none !important;
    line-height: 190%;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
}
