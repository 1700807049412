.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  }

.heading {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 20px;
  }

.container p {
    font-size: 1.2rem;
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
  }

.doc-link {
    font-weight: bold;
    text-decoration: none;
  }

.doc-link:hover {
    text-decoration: underline;
  }
