.outer{
    width:25%;
    margin:auto;
}
.outer form {
    margin: 0 auto;
    max-width: 100%;
  }

.form-control-forgot{
  width: 90%!important;
  height: 48px;
  border-radius: 5px;
  border: 1px solid #d6d6d6;
  margin-top: 4px;
}
.form-control-forgot #reset_password_newPassword{
    margin-right: 120px;
  }
.form-control:focus-visible{
  outline: 1px solid #d6d6d6;
}
.form-control:focus{
  border-color:#bfbaba!important;
  box-shadow: 0 0 0 0.25rem #f4f3f3!important;
}
#forgot_password .ant-form-item-required{
    color: rgb(79, 79, 79);
    margin-top: 20px;
  }
#reset_password .ant-form-item-required{
color: rgb(79, 79, 79);
margin-top: 20px;
}
.outer .label-bar{
    font-size: xx-large;
    font-weight: bold;
    margin-left: -15px;
}
.outer .reset-button{
    width: 90%;
    background-color: #27ddcc!important;
    border-color: #27ddcc!important;
    margin-top: 20px;
  }
  .outer .reset-button:hover{
    box-shadow: 0 8px 9px -4px #27998d, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .outer .reset-button:focus-visible{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .outer .reset-button:active{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
