.outer-smiles-div{
    display:flex;
    justify-content: center;
}
.smiles-outer-card{
    width: 50%;
}
.smiles-input-div{
    display:flex;
    justify-content: center;
}
.smile-value-text{
    width: 350px!important;
    height: 30%!important;
}
.molecule-search-smiles{
    width: 40%;
    margin-top: 3%;
    background-color: #27ddcc!important;
    border-color: #27ddcc!important;
  }
  .molecule-search-smiles:hover{
    box-shadow: 0 8px 9px -4px #27998d, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .molecule-search-smiles:focus-visible{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .molecule-search-smiles:active{
    box-shadow: 0 8px 9px -4px #02ae9e, 0 4px 18px 0 #02ae9e;
    background-color: #02ae9e!important;
    border-color: #02ae9e!important;
  }
  .search-btn-div{
    display:flex;
    justify-content: center;
}
.smiles-heading-div{
    display:flex;
    justify-content: center;
    margin-bottom: 20px;
}
